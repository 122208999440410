import '../styles/footer.css';

function Footer() {
  return (
    <div className="Footer">
      <a className="Footer-link" rel="noopener noreferrer" target="_blank" href="mailto:inquiries@chasedoes.dev?subject=Asking Chase a Question:">Ask Chase</a>
      <a className="Footer-link" rel="noopener noreferrer" target="_blank" href="https://www.paypal.me/chasedoes/5">Buy Chase a Beer</a>
      <a className="Footer-link" rel="noopener noreferrer" target="_blank" href="mailto:contact@chasedoes.dev?subject=Contacting Chase About:">Contact Chase</a>
      <a className="Footer-link" rel="noopener noreferrer" target="_blank" href="/">Develop with Chase</a>
    </div>
  );
}

export default Footer;
