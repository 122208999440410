import '../styles/nav.css';

function Nav() {
  return (
    <ul className="Nav">
      <li className="Nav-list-item"><a href="/" className="Nav-link" rel="noopener noreferrer">Chase Does <div className="Nav-cycler">
        <span className="Nav-cycle active">Dev</span>
        <span className="Nav-cycle">Email</span>
        <span className="Nav-cycle">Apps</span>
        <span className="Nav-cycle">React</span>
        </div></a></li>
      <li className="Nav-list-item"><a href="/work" className="Nav-link" rel="noopener noreferrer">Work Done</a></li>
      <li className="Nav-list-item"><a href="/emails" className="Nav-link" rel="noopener noreferrer">Emails Done</a></li>
      <li className="Nav-list-item"><a href="/about" className="Nav-link" rel="noopener noreferrer">About Chase</a></li>
    </ul>
  );
}

export default Nav;
