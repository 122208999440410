import '../styles/hero.css';

function Hero() {
  return (
    <div className="Hero">
      <h1>What do you need done?</h1>
      <a className="Hero-button" href="mailto:contact@chasedoes.dev?subject=Contacting Chase About:" target="_blank" rel="noopener noreferrer">Let Chase Do&nbsp;&raquo;</a>
    </div>
  );
}

export default Hero;
