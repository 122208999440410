import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import Nav from './components/nav';
import What from './components/what';
import Hero from './components/hero';
import Does from './components/does';
import Footer from './components/footer';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Nav />
    <What />
    <Hero />
    <Does />
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Nulla porttitor massa id neque. In fermentum posuere urna nec tincidunt. Arcu non sodales neque sodales ut etiam sit. Nunc sed id semper risus in. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Blandit massa enim nec dui nunc. Neque egestas congue quisque egestas. Diam in arcu cursus euismod quis viverra nibh cras pulvinar. Pretium viverra suspendisse potenti nullam.</p>
    <p>Pulvinar elementum integer enim neque volutpat. Lobortis mattis aliquam faucibus purus in massa. Risus ultricies tristique nulla aliquet enim. Duis at consectetur lorem donec. Neque aliquam vestibulum morbi blandit cursus risus at ultrices mi. Mauris pellentesque pulvinar pellentesque habitant morbi. Tristique nulla aliquet enim tortor at auctor. Nibh nisl condimentum id venenatis a condimentum vitae sapien. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras. Tellus integer feugiat scelerisque varius morbi enim nunc faucibus a. Etiam erat velit scelerisque in dictum non consectetur. Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Ac auctor augue mauris augue neque gravida in. Nec feugiat nisl pretium fusce. Neque sodales ut etiam sit. Dui nunc mattis enim ut tellus elementum sagittis.</p>
    <p>Dolor sit amet consectetur adipiscing elit pellentesque. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Tincidunt augue interdum velit euismod in. Dis parturient montes nascetur ridiculus mus. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Leo a diam sollicitudin tempor id eu nisl. Vitae sapien pellentesque habitant morbi. Euismod in pellentesque massa placerat. Sed vulputate mi sit amet. Et malesuada fames ac turpis egestas integer eget aliquet nibh. Nibh tellus molestie nunc non blandit massa enim nec. Eu facilisis sed odio morbi quis commodo odio aenean. Malesuada fames ac turpis egestas. Enim nec dui nunc mattis enim ut tellus elementum. Enim sed faucibus turpis in eu mi bibendum neque egestas. Adipiscing at in tellus integer.</p>
    <p>Magna eget est lorem ipsum dolor. Pellentesque elit eget gravida cum sociis natoque penatibus et magnis. Feugiat nisl pretium fusce id velit ut tortor pretium viverra. Vel quam elementum pulvinar etiam non quam. Morbi tristique senectus et netus et malesuada fames. Nec dui nunc mattis enim ut tellus elementum sagittis vitae. Ullamcorper malesuada proin libero nunc. Ut pharetra sit amet aliquam id diam. Volutpat lacus laoreet non curabitur gravida arcu ac tortor dignissim. Malesuada fames ac turpis egestas. Netus et malesuada fames ac turpis egestas integer.</p>
    <p>Maecenas ultricies mi eget mauris pharetra et ultrices neque. Auctor neque vitae tempus quam pellentesque. Tellus orci ac auctor augue mauris. Mattis aliquam faucibus purus in massa tempor nec feugiat. Suspendisse interdum consectetur libero id faucibus nisl. Sed risus pretium quam vulputate dignissim. Lobortis feugiat vivamus at augue eget arcu dictum varius. Eget nunc scelerisque viverra mauris in aliquam sem fringilla. In arcu cursus euismod quis viverra nibh. Ut eu sem integer vitae justo eget magna fermentum iaculis. Arcu vitae elementum curabitur vitae nunc sed velit. Sed vulputate odio ut enim blandit volutpat maecenas volutpat blandit.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Nulla porttitor massa id neque. In fermentum posuere urna nec tincidunt. Arcu non sodales neque sodales ut etiam sit. Nunc sed id semper risus in. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Blandit massa enim nec dui nunc. Neque egestas congue quisque egestas. Diam in arcu cursus euismod quis viverra nibh cras pulvinar. Pretium viverra suspendisse potenti nullam.</p>
    <p>Pulvinar elementum integer enim neque volutpat. Lobortis mattis aliquam faucibus purus in massa. Risus ultricies tristique nulla aliquet enim. Duis at consectetur lorem donec. Neque aliquam vestibulum morbi blandit cursus risus at ultrices mi. Mauris pellentesque pulvinar pellentesque habitant morbi. Tristique nulla aliquet enim tortor at auctor. Nibh nisl condimentum id venenatis a condimentum vitae sapien. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras. Tellus integer feugiat scelerisque varius morbi enim nunc faucibus a. Etiam erat velit scelerisque in dictum non consectetur. Praesent elementum facilisis leo vel fringilla est ullamcorper eget nulla. Ac auctor augue mauris augue neque gravida in. Nec feugiat nisl pretium fusce. Neque sodales ut etiam sit. Dui nunc mattis enim ut tellus elementum sagittis.</p>
    <p>Dolor sit amet consectetur adipiscing elit pellentesque. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Tincidunt augue interdum velit euismod in. Dis parturient montes nascetur ridiculus mus. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Leo a diam sollicitudin tempor id eu nisl. Vitae sapien pellentesque habitant morbi. Euismod in pellentesque massa placerat. Sed vulputate mi sit amet. Et malesuada fames ac turpis egestas integer eget aliquet nibh. Nibh tellus molestie nunc non blandit massa enim nec. Eu facilisis sed odio morbi quis commodo odio aenean. Malesuada fames ac turpis egestas. Enim nec dui nunc mattis enim ut tellus elementum. Enim sed faucibus turpis in eu mi bibendum neque egestas. Adipiscing at in tellus integer.</p>
    <p>Magna eget est lorem ipsum dolor. Pellentesque elit eget gravida cum sociis natoque penatibus et magnis. Feugiat nisl pretium fusce id velit ut tortor pretium viverra. Vel quam elementum pulvinar etiam non quam. Morbi tristique senectus et netus et malesuada fames. Nec dui nunc mattis enim ut tellus elementum sagittis vitae. Ullamcorper malesuada proin libero nunc. Ut pharetra sit amet aliquam id diam. Volutpat lacus laoreet non curabitur gravida arcu ac tortor dignissim. Malesuada fames ac turpis egestas. Netus et malesuada fames ac turpis egestas integer.</p>
    <p>Maecenas ultricies mi eget mauris pharetra et ultrices neque. Auctor neque vitae tempus quam pellentesque. Tellus orci ac auctor augue mauris. Mattis aliquam faucibus purus in massa tempor nec feugiat. Suspendisse interdum consectetur libero id faucibus nisl. Sed risus pretium quam vulputate dignissim. Lobortis feugiat vivamus at augue eget arcu dictum varius. Eget nunc scelerisque viverra mauris in aliquam sem fringilla. In arcu cursus euismod quis viverra nibh. Ut eu sem integer vitae justo eget magna fermentum iaculis. Arcu vitae elementum curabitur vitae nunc sed velit. Sed vulputate odio ut enim blandit volutpat maecenas volutpat blandit.</p>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
