import '../styles/does.css';

function Does() {
  return (
    <div className="Does">
      <h2>Chase Does:</h2>
      <small>Click for Examples</small>
      <ul className="Does-list">
      	<li>Email Compatibility</li>
      	<li>Accessibility</li>
      	<li>MySQL</li>
      	<li>Frontend</li>
      	<li>React + Native</li>
      	<li>SalesForce ExactTarget Marketing Cloud</li>
      	<li>Selligent</li>
      	<li>Firebase</li>
      	<li>PHP/Laravel</li>
      	<li>Wordpress/Squarespace</li>
      	<li>Technical Documentation</li>
      	<li>C#/Unity</li>
      	<li>MapBox</li>
      	<li>Loading Animations</li>
      	<li>Custom or Third-party Form Integrations</li>
      	<li>Parallax</li>
      	<li>HTML5 Banner Ads</li>
      	<li>Code Testing</li>
      	<li>Domain Management</li>
      	<li>Full-stack Dev</li>
      </ul>
    </div>
  );
}

export default Does;
