import React from 'react';
import '../styles/what.css';

function What() {
  const [showContents, setShowContents] = React.useState(false)
  const WhatClick = () => {
    console.log("clicked");
    !showContents ? setShowContents(true) : setShowContents(false);
  }

  return (
    <div className="What">
      <button className="What-button" rel="noopener noreferrer" onClick={WhatClick}>What&rsquo;s Here?</button>
      {showContents ? <Contents /> : null}
    </div>
  );
}

const Contents = () => (
  <div className="Contents">
    Some Contents
  </div>
)

export default What;
